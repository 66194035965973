import type { TFunction } from '@wix/yoshi-flow-editor';
import type { MenuState } from './types';
import type { PlaceholderImageDisplayOption, ZeroPriceDisplayOption } from './panels/Settings/types';

export const SHOW_MENUS_OPTIONS = {
  ALL: 'all',
  SPECIFIC: 'specific',
};

export const SHOW_PLACEHOLDER_IMAGE_OPTIONS = {
  NEVER: 'never',
  ALL: 'all',
  SPECIFIC: 'specific',
} as const;

export const ZERO_PRICE_DISPLAY_OPTIONS = {
  SHOW_ON_ALL: 'show on all',
  SHOW_ON_SPECIFIC: 'show on specific',
  DONT_SHOW: 'dont show',
} as const;

export const MENU_WIDGET_NAME = 'Menu';

export const NAVIGATION_STATES = {
  tabs: 'tabs',
  dropdown: 'dropdown',
};
export const NAVIGATION_BAR_ARIA_LABEL = 'menus';
export const NAVIGATION_BAR_ROLE = 'navigation';

export const MENU_NAVIGATION_LAYOUT_PANEL_NAME = 'MenuTabs';

export const MENU_STATES: Record<string, MenuState> = {
  empty: 'empty',
  error: 'error',
  menu: 'menu',
};

// Todo => remove default label once specs.restaurants.menusPanelsTranslationEnabled experiment removed
export const PRESET_NAME_TO_TRANSLATION_KEY_MAP = {
  'List 1 column': { translationKey: 'layout-panel.center-layout.label', defaultLabel: 'Center' },
  'List 2 column': { translationKey: 'layout-panel.two-columns-layout.label', defaultLabel: '2 Columns' },
  'List 3 column': { translationKey: 'layout-panel.three-columns-layout.label', defaultLabel: '3 Columns' },
  'List 1 column w/image': { translationKey: 'layout-panel.strip-layout.label', defaultLabel: 'Strip' },
  'Side by Side 1 column': { translationKey: 'layout-panel.sbs-layout.label', defaultLabel: 'Side by side' },
  Grid: { translationKey: 'layout-panel.grid-layout.label', defaultLabel: 'Grid' },
};

export const PRESET_NAME_TO_TRANSLATION_KEY_MAP_MOBILE = {
  'List 2&3 column - mobile': { translationKey: 'layout-panel.mobile.left-layout.label', defaultLabel: 'Left' },
  'List 1 column': { translationKey: 'layout-panel.mobile.center-layout.label', defaultLabel: 'Center' },
  'List 1 column w/image - mobile': {
    translationKey: 'layout-panel.mobile.strip-layout.label',
    defaultLabel: 'Strip',
  },
  'Grid mobile': { translationKey: 'layout-panel.mobile.cards-layout.label', defaultLabel: 'Cards' },
};

export const LAYOUT_PANEL_DEFAULT_LABEL = 'Choose a layout';

export const LAYOUT_CUSTOMIZATION_PANEL_NAME = 'LayoutCustomization';

export const PLACEHOLDER_IMAGE_DISPLAY_DROPDOWN_OPTIONS = (t: TFunction): PlaceholderImageDisplayOption[] => [
  {
    label: t('settings-panel.placeholder-image-section.dropdown.never'),
    value: SHOW_PLACEHOLDER_IMAGE_OPTIONS.NEVER,
  },
  {
    label: t('settings-panel.placeholder-image-section.dropdown.all'),
    value: SHOW_PLACEHOLDER_IMAGE_OPTIONS.ALL,
  },
  {
    label: t('settings-panel.placeholder-image-section.dropdown.specific'),
    value: SHOW_PLACEHOLDER_IMAGE_OPTIONS.SPECIFIC,
  },
];

export const ZERO_PRICE_DISPLAY_DROPDOWN_OPTIONS = (t: TFunction): ZeroPriceDisplayOption[] => [
  {
    label: t('settings-panel.price-options-section.dropdown.show-on-all'),
    value: ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_ALL,
  },
  {
    label: t('settings-panel.price-options-section.dropdown.show-on-specific'),
    value: ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_SPECIFIC,
  },
  {
    label: t('settings-panel.price-options-section.dropdown.dont-show'),
    value: ZERO_PRICE_DISPLAY_OPTIONS.DONT_SHOW,
  },
];
