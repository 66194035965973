import { ITEMS_WIDGET_COMPONENT_IDS, LABELS_LIMIT, LABELS_STATES } from './consts';
import { getImageSrc, getLabelCompElementIds, getPlaceholderImage, setImageDataOrRemoveFromDOMIfNeeded } from './utils';
import type { ItemWithLabelsAndVariants, Item, Preset, ControllerParams } from 'root/types';
import { ELEMENT_PROPERTY, MENU_WIDGET_PRESET_WITH_IMG_IDS } from 'root/utils/consts';
import type { I$W } from '@wix/yoshi-flow-editor';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { setPriceContainer } from 'root/utils/priceDisplay';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'root/assets/images';
import type itemsModel from './model';
import { state } from 'root/state/state';
import { getShouldDeleteZeroPrice, getShouldDisplayPlaceholderImage } from 'root/utils/settingsUtils';

type Bind = ControllerParams<typeof itemsModel>['$bind'];
type Props = ControllerParams<typeof itemsModel>['$props'];

export class ItemsController {
  constructor(
    private $w: I$W,
    private $bind: Bind,
    private $props: Props,
    private isViewer?: boolean,
    private priceFormatter?: (price: string, shouldDisplayCurrency: boolean) => string | undefined,
    isLabelLayouterWidgetExperimentEnabled?: boolean,
    isUseBindExperimentEnabled?: boolean,
    isPopulatedItemExperimentEnabled?: boolean
  ) {
    if (!isUseBindExperimentEnabled) {
      this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
        async (
          $item: I$W,
          itemData: ItemWithLabelsAndVariants & {
            _id: string;
            currency: string;
            preset: Preset;
            shouldDisplayCurrency: boolean;
            shouldDisplayVariantCurrency: boolean;
            shouldDisplayZeroPrice: boolean;
            shouldDisplayPlaceholderImage: boolean;
            placeholderImage?: string;
          }
        ) => {
          const itemHasPriceVariants =
            ((isPopulatedItemExperimentEnabled ? itemData.priceVariants?.variants : itemData.priceVariants) || [])
              .length > 0;
          const itemHasNoPrice = Number(itemData.price || '0.00') === 0;

          $item(ITEMS_WIDGET_COMPONENT_IDS.itemTitle).text = itemData.name;

          setElementDataAndCollapseIfNeeded(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS.itemDescription,
            ELEMENT_PROPERTY.TEXT,
            itemData.description
          );

          setImageDataOrRemoveFromDOMIfNeeded(
            $item,
            itemData,
            itemData.preset,
            getPlaceholderImage({
              shouldDisplayPlaceholderImage: itemData.shouldDisplayPlaceholderImage,
              placeholderImage: itemData.placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
            }),
            this.isViewer
          );

          const hasLabels = itemData.labels?.length;

          const labelsLayouterElement = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabelsLayouter);
          (!isLabelLayouterWidgetExperimentEnabled || !hasLabels) && (await labelsLayouterElement.collapse?.());

          const labelsElement = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabels);
          (isLabelLayouterWidgetExperimentEnabled || !hasLabels) && (await labelsElement.delete?.());

          if (hasLabels) {
            if (isLabelLayouterWidgetExperimentEnabled) {
              await labelsLayouterElement.expand();
              labelsLayouterElement.data = {
                labels: itemData.labels,
              };
            } else {
              labelsElement.deleted && (await labelsElement.restore());
              this.initLabels($item, itemData);
            }
          }

          const priceVariantsContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsBox);
          const priceContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPrice);
          const priceCurrencyContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency);
          priceCurrencyContainer.delete();

          if (!itemHasPriceVariants) {
            await priceVariantsContainer.delete();
            await priceContainer.delete();
            if (!itemHasNoPrice || itemData.shouldDisplayZeroPrice) {
              await priceContainer.restore();
              priceContainer.text = this.priceFormatter?.(itemData.price || '0.00', itemData.shouldDisplayCurrency);
            }
          } else {
            await priceContainer.delete();
            await priceVariantsContainer.delete();
            await priceVariantsContainer.restore();
            $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data = {
              priceVariants: isPopulatedItemExperimentEnabled
                ? itemData.priceVariants?.variants
                : itemData.priceVariants,
              shouldDisplayVariantCurrency: itemData.shouldDisplayVariantCurrency,
              shouldDisplayZeroPrice: itemData.shouldDisplayZeroPrice,
            };
          }
        }
      );
    }
  }

  init(
    priceFormatter: (price: string, shouldDisplayCurrency: boolean) => string,
    isViewer: boolean,
    isMobile: boolean,
    isLabelLayouterWidgetExperimentEnabled: boolean,
    isPopulatedItemExperimentEnabled: boolean
  ) {
    this.$bind(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater, {
      data: () =>
        (this.$props.data?.items || []).map(({ id, ...rest }: ItemWithLabelsAndVariants) => ({
          _id: id,
          sectionId: this.$props.data?.sectionId,
          ...rest,
        })),
      item(item: ItemWithLabelsAndVariants & { sectionId: string }, $bindItem) {
        const itemHasPriceVariants =
          ((isPopulatedItemExperimentEnabled ? item.priceVariants?.variants : item.priceVariants) || []).length > 0;
        const itemHasLabels = (item.labels || []).length > 0;

        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemTitle, {
          text: () => item.name || '',
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemDescription, {
          text: () => item.description || '',
          deleted: () => !item.description,
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemPrice, {
          text: () => priceFormatter(item.price || '0.00', state.shouldDisplayCurrency),
          deleted: () =>
            itemHasPriceVariants ||
            getShouldDeleteZeroPrice({
              price: item.price,
              zeroPriceDisplayOption: state.zeroPriceDisplayOption,
              sectionId: item.sectionId,
              zeroPriceDisplaySpecificSectionIds: state.zeroPriceDisplaySpecificSectionIds,
            }),
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency, {
          deleted: () => true,
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsBox, {
          deleted: () => !itemHasPriceVariants,
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater, {
          data: () => ({
            priceVariants: isPopulatedItemExperimentEnabled
              ? item.priceVariants?.variants || []
              : item.priceVariants || [],
            sectionId: item.sectionId,
          }),
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemImage, {
          src: () =>
            getImageSrc({
              placeholderImageDisplayValue: state.placeholderImageDisplayValue,
              sectionId: item.sectionId,
              sectionsWithPlaceholderImageIds: state.sectionsWithPlaceholderImageIds,
              placeholderImage: state.placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
              image: item.image,
            }) || DEFAULT_PLACEHOLDER_IMAGE,
          alt: () => `${item.name} image`,
          deleted: () => {
            const shouldDeleteImageFromDom =
              !!isViewer && !isMobile && !MENU_WIDGET_PRESET_WITH_IMG_IDS.includes(state.preset);
            const shouldDisplayPlaceholderImage = getShouldDisplayPlaceholderImage(
              state.placeholderImageDisplayValue,
              item.sectionId,
              state.sectionsWithPlaceholderImageIds
            );
            return (!item.image?.url && !shouldDisplayPlaceholderImage) || shouldDeleteImageFromDom;
          },
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemLabels, {
          deleted: () => isLabelLayouterWidgetExperimentEnabled || !itemHasLabels,
        });
        $bindItem(ITEMS_WIDGET_COMPONENT_IDS.itemLabelsLayouter, {
          data: () => ({ labels: item.labels || [] }),
          deleted: () => !isLabelLayouterWidgetExperimentEnabled || !itemHasLabels,
        });
        if (!isLabelLayouterWidgetExperimentEnabled && itemHasLabels) {
          const itemLabelContainerIds = getLabelCompElementIds('itemLabelsTextIconContainer');
          const textElementIds = getLabelCompElementIds('itemLabelsTextIconLabelText');
          const iconElementIds = getLabelCompElementIds('itemLabelsTextIconLabelIcon');

          for (let i = 0; i < LABELS_LIMIT; i++) {
            const currentLabel = item.labels?.[i];

            $bindItem(ITEMS_WIDGET_COMPONENT_IDS[itemLabelContainerIds[i]], {
              deleted: () => !currentLabel,
            });
            $bindItem(ITEMS_WIDGET_COMPONENT_IDS[textElementIds[i]], {
              ...(currentLabel?.name ? { text: () => currentLabel.name } : {}),
              deleted: () => !currentLabel,
            });
            $bindItem(ITEMS_WIDGET_COMPONENT_IDS[iconElementIds[i]], {
              ...(currentLabel?.icon?.url ? { src: () => currentLabel.icon?.url } : {}),
              deleted: () => !currentLabel,
            });
          }
        }
      },
    });
  }

  updateCurrency(shouldDisplayCurrency: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ItemWithLabelsAndVariants & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
        }
      ) => {
        const priceContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPrice);
        priceContainer.text = this.priceFormatter?.(itemData.price || '0.00', shouldDisplayCurrency);
      }
    );
  }

  updateVariantCurrency(shouldDisplayVariantCurrency: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(async ($item: I$W) => {
      $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data.shouldDisplayVariantCurrency =
        shouldDisplayVariantCurrency;
    });
  }

  updatePlaceholderImage(placeholderImage?: string, shouldDisplayPlaceholderImage?: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ItemWithLabelsAndVariants & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          shouldDisplayPlaceholderImage: boolean;
          placeholderImage?: string;
        }
      ) => {
        setImageDataOrRemoveFromDOMIfNeeded(
          $item,
          itemData,
          itemData.preset,
          getPlaceholderImage({
            placeholderImage: placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
            shouldDisplayPlaceholderImage: !!shouldDisplayPlaceholderImage,
          }),
          this.isViewer
        );
      }
    );
  }

  updateShouldDisplayZeroPrice(shouldDisplayZeroPrice: boolean, isPopulatedItemExperimentEnabled: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ItemWithLabelsAndVariants & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
        }
      ) => {
        const itemHasPriceVariants =
          ((isPopulatedItemExperimentEnabled ? itemData.priceVariants?.variants : itemData.priceVariants) || [])
            .length > 0;

        if (!itemHasPriceVariants) {
          setPriceContainer(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS.itemPrice,
            itemData.price,
            shouldDisplayZeroPrice,
            itemData.shouldDisplayCurrency,
            this.priceFormatter
          );
        } else {
          this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(async ($item_: I$W) => {
            $item_(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data.shouldDisplayZeroPrice =
              shouldDisplayZeroPrice;
          });
        }
      }
    );
  }

  initWithW(
    items: Item[],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    shouldDisplayZeroPrice: boolean,
    shouldDisplayPlaceholderImage: boolean,
    placeholderImage: string | undefined
  ) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater).data = items?.map(({ id, ...rest }) => ({
      _id: id,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      shouldDisplayZeroPrice,
      shouldDisplayPlaceholderImage,
      placeholderImage,
      ...rest,
    }));
  }

  initLabels($item: I$W, itemData: ItemWithLabelsAndVariants & { _id: string; currency: string }) {
    const { currentState } = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabels);

    switch (currentState.id) {
      case LABELS_STATES.TEXT_AND_ICON: {
        const textElementIds = getLabelCompElementIds('itemLabelsTextIconLabelText');
        const iconElementIds = getLabelCompElementIds('itemLabelsTextIconLabelIcon');
        const itemLabelContainerIds = getLabelCompElementIds('itemLabelsTextIconContainer');
        for (let i = 0; i < LABELS_LIMIT; i++) {
          const currentLabel = itemData.labels?.[i];
          const labelElement = $item(ITEMS_WIDGET_COMPONENT_IDS[itemLabelContainerIds[i]]);
          if (currentLabel) {
            labelElement.deleted && labelElement.restore();
            $item(ITEMS_WIDGET_COMPONENT_IDS[textElementIds[i]]).text = currentLabel.name;
            const iconUrl = currentLabel.icon?.url;
            setElementDataAndCollapseIfNeeded(
              $item,
              ITEMS_WIDGET_COMPONENT_IDS[iconElementIds[i]],
              ELEMENT_PROPERTY.SRC,
              iconUrl
            );
          } else {
            labelElement.delete();
          }
        }
        break;
      }
      case LABELS_STATES.ICON_ONLY: {
        getLabelCompElementIds('itemLabelIconOnlyIcon').forEach((iconElementId, i) => {
          const currentLabel = itemData.labels?.[i];
          const iconUrl = currentLabel.icon?.url;
          setElementDataAndCollapseIfNeeded(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS[iconElementId],
            ELEMENT_PROPERTY.SRC,
            iconUrl
          );
        });
        break;
      }
      case LABELS_STATES.TEXT_ONLY: {
        getLabelCompElementIds('itemLabelTextOnlyText').forEach((textElementId, i) => {
          const currentLabel = itemData.labels?.[i];
          const labelText = currentLabel?.name;
          setElementDataAndCollapseIfNeeded(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS[textElementId],
            ELEMENT_PROPERTY.TEXT,
            labelText
          );
        });
        break;
      }
    }
  }

  removeImagesFromDOMIfNeeded($w: I$W, preset: Preset) {
    $w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater).forEachItem(
      (
        $item: I$W,
        itemData: ItemWithLabelsAndVariants & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          shouldDisplayPlaceholderImage: boolean;
          placeholderImage?: string;
        }
      ) => {
        setImageDataOrRemoveFromDOMIfNeeded(
          $item,
          itemData,
          preset,
          getPlaceholderImage({
            shouldDisplayPlaceholderImage: itemData.shouldDisplayPlaceholderImage,
            placeholderImage: itemData.placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
          }),
          this.isViewer
        );
      }
    );
  }
}
